






























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import Info from '@/components/common/Info.vue'
import FilterTags from '@/components/common/FilterTags.vue'
import TasksTable from '@/components/tasks/TasksTable.vue'
import AddTaskForm from '@/components/tasks/AddTaskForm.vue'
import ReturnTaskForm from '@/components/tasks/ReturnTaskForm.vue'
import TaskInfo from '@/components/tasks/TaskInfo.vue'
import {
  ResponseDetailTaskAnswerType,
  ResponseDetailTaskType,
  TaskAnswersType,
  TasksItemListType,
} from '@/types/tasks'
import { TableFilterType, TableOrderType } from '@/types/general'
import SelectCommon from '@/components/common/SelectCommon.vue'
import TasksCard from '@/components/mobile/tasks/TasksCard.vue'
import Drawer from '@/components/common/Drawer.vue'
import TasksFilters from '@/components/mobile/tasks/TasksFilters.vue'
import { Message } from 'element-ui'
import { taskAnswerAPI } from '@/api/taskanswer.api'
import { MembersListItemType } from '@/types/members'
import MembersDetail from '@/components/members/MembersDetail.vue'
import { ShopsListItemType } from '@/types/shops'
import ShopInfo from '@/components/shops/ShopInfo.vue'

@Component({
  components: {
    MembersDetail,
    TasksFilters,
    Drawer,
    TasksCard,
    SelectCommon,
    TaskInfo,
    EllipseCommon,
    Info,
    FilterTags,
    TasksTable,
    AddTaskForm,
    ReturnTaskForm,
    ShopInfo,
  },
})
export default class Tasks extends Vue {
  filter = ''
  filters: TableFilterType[] = []
  filterNames = {
    USER_LOGIN: 'Ответственный',
  }

  order: TableOrderType | Record<string, any> = {}
  limit = 3
  taskAnswers: TaskAnswersType = {} as TaskAnswersType
  page = 1

  showTaskForm = false
  showReturnTaskForm = false
  showMemberInfo = false
  showShopInfo = false
  showTaskInfo = false

  loading = false
  loadingInfo = false

  showFilters = false
  showTasksFilters = false
  isAnimationEnd = false

  showAllComments = true

  filterOptions = [
    {
      label: 'Все',
      value: '',
    },
    {
      label: 'Новые',
      value: 'open',
    },
    {
      label: 'Активные',
      value: 'active',
    },
    {
      label: 'Закрытые',
      value: 'closed',
    },
  ]

  taskAnswer: ResponseDetailTaskAnswerType | {} =
    {} as ResponseDetailTaskAnswerType

  get tasksList(): TasksItemListType[] {
    return this.$store.getters['tasks/tasksList']
  }

  get tasksDetail(): TasksItemListType {
    return this.$store.getters['tasks/tasksDetail']
  }

  get tasksTotal(): string {
    return this.$store.getters['tasks/tasksTotal']
  }

  get membersDetail(): MembersListItemType {
    return this.$store.getters['members/membersDetail']
  }

  get shopsDetail(): ShopsListItemType {
    return this.$store.getters['shops/shopsDetail']
  }

  get tasksTypeCount() {
    const response = this.$store.getters['tasks/tasksCount']
    let result = {
      open: 0,
      active: 0,
      closed: 0,
    }

    response.forEach((value) => {
      result[value.status] = value.count
    })

    return result
  }

  get tableFilters() {
    const params = {
      filter: {
        UF_TYPE: 'task',
      },
    }

    this.filters.forEach((data) => {
      let value = data.data

      switch (value) {
        case 'Закрыта':
          value = 'closed'
          break
        case 'Новая':
          value = 'open'
          break
        case 'Активна':
          value = 'active'
          break

        default:
          value = data.data
      }
      params.filter[data.target] = value
    })

    if (this.filter) {
      params.filter['UF_STATUS'] = this.filter
    }

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  get showedTasksCount() {
    if (this.tasksTotal) {
      return 10 * this.page > parseInt(this.tasksTotal)
        ? this.tasksTotal
        : 10 * this.page
    } else {
      return 0
    }
  }

  get tasksExport(): any {
    return this.$store.getters['tasks/exportTasks']
  }

  async handleShowShopInfo(
    id: ResponseDetailTaskType['UF_REMOTE_SHOP_ID']
  ): Promise<void> {
    this.showTaskInfo = false
    this.$router.push({
      name: 'Tasks',
      query: { ...this.$route.query, taskId: undefined },
    })
    this.showMemberInfo = false

    await this.$store.dispatch('shops/getShopsDetail', id)

    this.showShopInfo = true
  }

  async handleShowMemberInfo(id: number): Promise<void> {
    this.showTaskInfo = false
    this.$router.push({
      name: 'Tasks',
      query: { ...this.$route.query, taskId: undefined },
    })
    this.loadingInfo = true
    this.showShopInfo = false

    this.showMemberInfo = true

    await this.$store.dispatch('members/getMembersDetail', id)
    this.loadingInfo = false
  }

  handleShowTaskForm(): void {
    this.showMemberInfo = false
    this.showTaskForm = true
  }

  async handleCloseTaskForm(): Promise<void> {
    this.loading = true
    this.filter = ''

    await this.$store.dispatch('tasks/getTasksList')
    await this.$store.dispatch('tasks/getTasksCount')

    this.loading = false
    this.showTaskForm = false
  }

  handleShowReturnTaskForm(): void {
    this.showReturnTaskForm = true
  }

  handleCloseShopInfo(): void {
    this.showShopInfo = false
  }

  handleTaskInfoClose(): void {
    this.showTaskInfo = false
    this.$router.push({
      name: 'Tasks',
      query: { ...this.$route.query, taskId: undefined },
    })

    this.handleChangePage()
  }

  handleCloseReturnTaskForm(): void {
    this.showReturnTaskForm = false
  }

  async getTaskAnswers(id?: number): Promise<void> {
    const [error, data] = await taskAnswerAPI.getTaskAnswers({
      offset: 0,
      filter: {
        UF_TASK_ID: id,
      },
      order: {
        ID: 'DESC',
      },
      limit: this.limit,
    })

    if (!error && data) {
      this.taskAnswers = data
    }
  }

  async handleShowMoreComments(id: string): Promise<void> {
    this.limit = 0
    this.showAllComments = false

    await this.getTaskAnswers(+id)

    this.limit = 3
  }

  async handleShowTaskInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showTaskInfo = true
    this.showAllComments = true

    await this.$store.dispatch('tasks/getTasksDetail', id)
    await this.getTaskAnswers(id)

    this.loadingInfo = false

    setTimeout(() => {
      this.isAnimationEnd = true
    }, 200)
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  handleAddTableFilter(data: TableFilterType): void {
    const foundFilterIndex = this.filters.findIndex(
      (filter) => filter.target === data.target
    )

    if (foundFilterIndex === -1) {
      if (data.data === '') {
        return
      }

      this.filters.push({
        data: data.data,
        target: data.target,
        name: this.filterNames[data.target],
      })
    } else {
      if (data.data === '') {
        this.handleDeleteFilter(foundFilterIndex)
      }

      this.filters.forEach((item) => {
        if (item.target === data.target) {
          this.handleDeleteFilter(foundFilterIndex)

          this.filters.push({
            data: data.data,
            target: data.target,
            name: this.filterNames[data.target],
          })
        }
      })
    }

    this.isAnimationEnd = true
  }

  async handleChangePage(): Promise<void> {
    const tabs = document.querySelector('.tasks__filter') as HTMLElement
    tabs.scrollIntoView({ behavior: 'smooth' })

    const params = {
      filter: {},
      order: {},
      offset: 0,
    }

    params.filter = this.tableFilters.filter
    params.order = this.tableOrder
    params.offset = (this.page - 1) * 10

    this.showAllComments = true

    this.loading = true

    await this.$store.dispatch('tasks/getTasksList', params)

    this.loading = false
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data
    this.handleChangePage()
  }

  handleShowFilters(): void {
    this.showFilters = !this.showFilters
  }

  handleShowTasksFilters(): void {
    this.showTasksFilters = !this.showTasksFilters
  }

  handleSelectFilter(option: string): void {
    this.filter = option
    this.handleShowFilters()
  }

  handleSelectFilters(data: TableFilterType[]): void {
    this.handleShowTasksFilters()

    data.forEach((value) => {
      this.handleAddTableFilter(value)
    })
  }

  lazyDownload(link: HTMLElement): void {
    link.addEventListener('click', () => {
      link.setAttribute(
        'href',
        process.env.VUE_APP_BASE_URI + this.tasksExport.link
      )
      link.setAttribute('download', this.tasksExport.name)
      link.remove()
    })

    link.click()
  }

  async handleExportTasks(): Promise<void> {
    await this.$store.dispatch('tasks/getExportTasks', {
      filter: this.tableFilters,
      order: this.tableOrder,
    })

    const link = document.createElement('a')

    if (
      this.tasksExport.type === 'error' ||
      this.tasksExport.type === 'queue'
    ) {
      Message.error(this.tasksExport.text)
    } else {
      this.lazyDownload(link)
    }
  }

  @Watch('filter')
  async onFilterChange(): Promise<void> {
    this.loading = true

    this.showTaskInfo = false
    this.$router.push({
      name: 'Tasks',
      query: { ...this.$route.query, taskId: undefined },
    })
    await this.$store.dispatch('tasks/getTasksList', this.tableFilters)

    this.loading = false
  }

  @Watch('filters')
  async onFiltersChange(): Promise<void> {
    this.loading = true
    this.showTaskInfo = false
    this.$router.push({
      name: 'Tasks',
      query: { ...this.$route.query, taskId: undefined },
    })

    await this.$store.dispatch('tasks/getTasksList', this.tableFilters)

    this.loading = false
  }

  async mounted(): Promise<void> {
    if (this.$route?.query?.taskId) {
      this.handleShowTaskInfo(+this.$route.query.taskId)
    }
    this.loading = true

    await this.$store.dispatch('tasks/getTasksList', this.tableFilters)
    await this.$store.dispatch('tasks/getTasksCount', this.tableFilters)

    this.loading = false
  }
}
